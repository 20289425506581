import React, { createContext, useState, useEffect } from 'react';

// import { colorModes } from '../utils/common';

import { languages } from '../utils/locale';
import { languageFromPath } from '../utils/locale';

const defaultValues = {
  // colorMode: colorModes[1],
  // setColorMode: () => false,
  language: languages.ENGLISH,
};

export const Context = createContext(defaultValues);

export const Provider = ({ children, location }) => {
  // get the language from the path

  const [menuOpen, setMenuOpen] = useState(false);
  // const [colorMode, setColorMode] = useState(colorModes[1]);
  const [language, setLanguage] = useState(languages.ENGLISH);
  const [selectedSeries, setSelectedSeries] = useState('all');
  const [selectedMediums, setSelectedMediums] = useState('all');

  // useEffect(() => {
  //   colorModes.forEach((cm) => {
  //     document.documentElement.classList.toggle(`${cm}-mode`, cm === colorMode);
  //   });
  // }, [colorMode]);

  return (
    <Context.Provider
      value={{
        menuOpen,
        setMenuOpen,
        // colorMode,
        // setColorMode,
        language,
        setLanguage,
        selectedSeries,
        setSelectedSeries,
        selectedMediums,
        setSelectedMediums,
      }}>
      {children}
    </Context.Provider>
  );
};
