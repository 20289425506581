import React from 'react';

import classNames from 'classnames';

import { Button } from '../Button';
import { Icon } from '../Icon';
import * as styles from './IconButton.module.scss';

const IconButton = ({
  icon,
  className,
  iconProps = {},
  sx = {},
  onClick,
  border = false,
  translate = true,
  children,
  bg,
  hiddenText,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      // type="button"
      className={classNames(
        styles.btn,
        [styles[bg]],
        {
          [styles.border]: border,
        },
        className
      )}
      translate={translate}
      {...props}>
      {hiddenText && <span className="visually-hidden">{hiddenText}</span>}
      {children}
      {icon && <Icon name={icon} className={styles.icon} {...iconProps} />}
    </Button>
  );
};

export { IconButton };
