import React from 'react';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/variables/colors.scss';
import './src/styles/variables/general.scss';
import './src/styles/variables/sizes.scss';
import './src/styles/variables/type.scss';
import './src/styles/global.scss';

import 'typeface-roboto';

import Layout from './src/components/layout/Layout';
import { Provider } from './src/context/Context';

export const wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>;
};
