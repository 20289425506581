// extracted by mini-css-extract-plugin
export var header = "Header-module--header--d1136";
export var homeIcon = "Header-module--home-icon--73a54";
export var logo = "Header-module--logo--8edd6";
export var logoLink = "Header-module--logo-link--d82e4";
export var menuBar = "Header-module--menu-bar--43661";
export var menuItem = "Header-module--menu-item--6f4f0";
export var menuTop = "Header-module--menu-top--dd442";
export var nav = "Header-module--nav--5b639";
export var topBar = "Header-module--top-bar--cc739";
export var typeBody = "Header-module--type-body--5ad67";
export var typeLarge = "Header-module--type-large--3b33f";
export var typeMedium = "Header-module--type-medium--f78f2";