import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

import { FLink } from "../FLink";
import * as styles from "./Button.module.scss";

const Button = ({
  to,
  children,
  onClick,
  className,
  translate = true,
  ...props
}) => {
  const cls = classNames(styles.btn, className);

  if (to) {
    return (
      <Link className={cls} to={to} onClick={onClick} {...props}>
        {children}
      </Link>
    );
  }
  if (to && translate) {
    return (
      <FLink className={cls} to={to} onClick={onClick} {...props}>
        {children}
      </FLink>
    );
  }

  return (
    <button className={cls} onClick={onClick} {...props}>
      {children}
    </button>
  );
};

export { Button };
