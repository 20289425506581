import React, { useLayoutEffect } from 'react';
import { Header } from '../Header';
import { Footer } from '../Footer';
import classNames from 'classnames';

import smoothscroll from 'smoothscroll-polyfill';

import * as styles from './Layout.module.scss';

const Layout = ({ children, location }) => {
  // const fullLayout = location.pathname !== "/";
  const fullLayout = true;
  const cls = classNames(
    { [styles.main]: fullLayout },
    { [styles.home]: !fullLayout }
  );
  useLayoutEffect(() => {
    // on load
    // make smoothscroll work on safari and other crap browsers
    smoothscroll.polyfill();
  }, []);
  return (
    <>
      {fullLayout && <Header location={location} />}
      <main className={cls}>{children}</main>
      {fullLayout && <Footer location={location} />}
    </>
  );
};

export default Layout;
