// extracted by mini-css-extract-plugin
export var homeIcon = "Icon-module--home-icon--cb59c";
export var icon = "Icon-module--icon--6c9a3";
export var large = "Icon-module--large--6de83";
export var medium = "Icon-module--medium--0b0a8";
export var normal = "Icon-module--normal--84aba";
export var small = "Icon-module--small--67d6e";
export var typeBody = "Icon-module--type-body--9db68";
export var typeLarge = "Icon-module--type-large--aeed2";
export var typeMedium = "Icon-module--type-medium--fd7d7";
export var xlarge = "Icon-module--xlarge--23c81";
export var xsmall = "Icon-module--xsmall--6b027";