// extracted by mini-css-extract-plugin
export var active = "Navbar-module--active--5066f";
export var homeIcon = "Navbar-module--home-icon--ad3c6";
export var location = "Navbar-module--location--9f4aa";
export var mainMenu = "Navbar-module--main-menu--5d2f7";
export var menu = "Navbar-module--menu--ee018";
export var mobileLocation = "Navbar-module--mobile-location--25bb2";
export var name = "Navbar-module--name--ee3e4";
export var typeBody = "Navbar-module--type-body--46987";
export var typeLarge = "Navbar-module--type-large--db47b";
export var typeMedium = "Navbar-module--type-medium--32ce6";