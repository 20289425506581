import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Context } from '../../../context/Context';

// we need to add all the possible props for Link below
// if they become necessary!
const FLink = ({
  to,
  alt,
  className,
  activeStyle,
  activeClassName,
  onClick,
  children,
}) => {
  // get the current language - this gets set in the LocationSwitch component in the header
  const { language } = useContext(Context);
  // get the current language path and add the 'to' field
  // if the language path is empty the language is english
  const path = language.path ? `${language.path}${to}` : to;

  // return a link with the translated path
  return (
    <Link
      to={path}
      onClick={onClick}
      className={className}
      activeClassName={activeClassName}
      activeStyle={activeStyle}>
      {children}
    </Link>
  );
};

export { FLink };
