import React, { useLayoutEffect, useState } from "react";
import { motion, Transition, SVGMotionProps } from "framer-motion";
import * as styles from "./Hamburger.module.scss";

const Hamburger = ({
  isOpen = false,
  width = 16,
  height = 26,
  strokeWidth = 1,
  color = "#000",
  transition = null,
  lineProps = null,
  onClick,
  ...props
}) => {
  const [svgWidth, setSvgWidth] = useState(0);
  const [svgHeight, setSvgHeight] = useState(0);
  useLayoutEffect(() => {
    const buttonHeight = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--button-height"
      ),
      10
    );
    setSvgWidth(buttonHeight / 4);
    setSvgHeight(buttonHeight / 3);
  }, []);

  const variant = isOpen ? "opened" : "closed";
  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: 45,
      translateY: 1,
    },
  };
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -1,
    },
  };
  lineProps = {
    stroke: color,
    strokeWidth: strokeWidth,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    transition,
    ...lineProps,
  };
  const unitHeight = 4;
  const unitWidth = Math.round((unitHeight * width) / height);

  return (
    <div className={styles.hamburger} onClick={onClick}>
      <motion.svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={svgWidth}
        height={svgHeight}
        {...props}
      >
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="0"
          y2="0"
          variants={top}
          {...lineProps}
        />
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="1"
          y2="1"
          variants={center}
          {...lineProps}
        />
        <motion.line
          x1="0"
          x2={unitWidth}
          y1="2"
          y2="2"
          variants={bottom}
          {...lineProps}
        />
      </motion.svg>
    </div>
  );
};

export { Hamburger };
