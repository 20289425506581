import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FLink } from '../../../common/FLink';
import * as styles from './FooterMenu.module.scss';
import { Context } from '../../../../context/Context';
import { localizeField } from '../../../../utils/locale';
import { IconButton } from '../../../common/IconButton';

const FooterMenu = () => {
  // const data = useStaticQuery(graphql`
  //   query FooterMenuQuery {
  //     sanitySingletonMenus {
  //       footerMenu {
  //         linkText {
  //           _type
  //           en
  //           sc
  //           tc
  //         }
  //         slug
  //       }
  //       nftLink {
  //         linkText {
  //           _type
  //           en
  //           sc
  //           tc
  //         }
  //         slug
  //       }
  //       signUpLink {
  //         linkText {
  //           _type
  //           en
  //           sc
  //           tc
  //         }
  //         slug
  //       }
  //     }
  //   }
  // `);

  return (
    <menu className={styles.footerMenu}>
      <div>&nbsp;</div>
      <div className={styles.footerMenuRight}>
        <div className={styles.buttonLinks}></div>
        <div className={styles.utilityMenu}></div>
      </div>
    </menu>
  );
};

export { FooterMenu };
