import React from "react"; // eslint-disable-line no-unused-vars

import classNames from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as ArrowRightIcon } from "./icons/right-arrow.svg";
import { ReactComponent as ArrowDownIcon } from "./icons/down-arrow.svg";
import { ReactComponent as BuyButton } from "./icons/buy-button-2.svg";
import { ReactComponent as Chevron } from "./icons/chevron.svg";
import { ReactComponent as External } from "./icons/external.svg";

import * as styles from "./Icon.module.scss";

const iconMap = {
  "arrow-right": ArrowRightIcon,
  "arrow-down": ArrowDownIcon,
  "buy-button": BuyButton,
  chevron: Chevron,
  external: External,
};

export const iconKeys = Object.keys(iconMap);

const Icon = ({ name, size = "normal", className, ...props }) => {
  if (!iconKeys.includes(name)) {
    return null;
  }

  const IconName = iconMap[name];

  return (
    <span
      className={classNames(styles.icon, [styles[size]], className)}
      {...props}
    >
      <IconName />
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(iconKeys).isRequired,
  size: PropTypes.oneOf([
    "xsmall",
    "small",
    "normal",
    "medium",
    "large",
    "free",
  ]),
};

export { Icon };
