import React, { useContext, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion, useViewportScroll } from 'framer-motion';
import * as styles from './BookTicketsButton.module.scss';
import { Context } from '../../../context/Context';
import { localizeField } from '../../../utils/locale';
import { IconButton } from '../../../components/common/IconButton';
import { Icon } from '../../common/Icon';
import { useMediaQuery, MediaQuery } from '../../../utils/hooks/mediaQuery';

import classNames from 'classnames';

const BookTicketsButton = ({ onClick, menuOpen, minHeight, maxHeight }) => {
  // const data = useStaticQuery(graphql`
  //   query BookTicketsButtonQuery {
  //     sanitySingletonMenus {
  //       bookTickets {
  //         linkText {
  //           _type
  //           en
  //           sc
  //           tc
  //         }
  //         slug
  //       }
  //       bookTicketsMobile {
  //         _type
  //         en
  //         sc
  //         tc
  //       }
  //     }
  //   }
  // `);

  // const bookTickets = data.sanitySingletonMenus.bookTickets;
  // const bookTicketsMobile = data.sanitySingletonMenus.bookTicketsMobile;

  const { language } = useContext(Context);
  // const [maxLogoHeight, setMaxLogoHeight] = useState(maxHeight);
  const isTablet = useMediaQuery(MediaQuery.Tablet);
  const [showIcon, setShowIcon] = useState(false);

  // animate the text/icon on scroll
  const { scrollY } = useViewportScroll();
  const minLogoHeight = minHeight;

  // no need to update if we don't land on the homepage
  const shouldUpdate = scrollY;
  useEffect(() => {
    // hook into the onChange, store the current value as state.
    scrollY.onChange((v) => {
      // console.log('scrollpos', v);
      setShowIcon(v > 100);
    });
  }, [shouldUpdate]); //make sure to re-subscriobe when scrollY changes

  const iconMobileStyles = classNames(styles.bookTicketsButton, {
    // [styles.mobileButton]: !isTablet,
    [styles.open]: menuOpen,
  });

  const mobileVariants = {
    open: {
      width: '100%',
    },
    closed: {
      width: '60px',
    },
  };
  const textVariants = {
    open: {
      width: '100%',
    },
    closed: {
      width: 0,
    },
  };
  const iconVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  };

  // const visuallyHidden = showIcon ? "visually-hidden" : ";"
  return (
    <menu className={styles.menu} onClick={onClick}>
      <div className={styles.bookTicketsButton}>
        {/* <FLink to={`/${path}`}>
          {localizeField(bookTickets.linkText, language.path)}
        </FLink> */}
        <div className={iconMobileStyles}>
          {isTablet ? (
            <IconButton to={``} border={false}>
              {/* {localizeField(bookTickets.linkText, language.locale)} */}
            </IconButton>
          ) : (
            <motion.div
              animate={!showIcon || menuOpen ? 'open' : 'closed'}
              variants={mobileVariants}
              initial={false}>
              <IconButton
                // to={`/${bookTickets.slug}`}
                border={false}
                className={styles.mobileButton}>
                {/* <div className={styles.mobileButtonInner}> */}
                <motion.div
                  animate={!showIcon || menuOpen ? 'open' : 'closed'}
                  variants={textVariants}
                  initial={false}>
                  {/* {localizeField(bookTicketsMobile, language.locale)} */}
                </motion.div>

                <motion.div
                  animate={showIcon && !menuOpen ? 'open' : 'closed'}
                  variants={iconVariants}
                  initial={false}
                  className={styles.mobileIconWrapper}>
                  <Icon name="buy-button" className={styles.mobileIcon} />
                </motion.div>
                {/* </div> */}
              </IconButton>
            </motion.div>

            // mobile button - reveal on scroll at some point...
            // <IconButton
            //   to={`/${bookTickets.slug}`}
            //   border={false}
            //   icon="buy-button"
            //   iconProps={{ size: "small" }}
            //   hiddenText="book tickets"
            //   className={styles.mobileButton}
            // ></IconButton>
          )}
        </div>
      </div>
    </menu>
  );
};

export { BookTicketsButton };

// import React, { useContext } from "react";
// import { useStaticQuery, graphql } from "gatsby";
// import { FLink } from "../../common/FLink";
// import * as styles from "./BookTicketsButton.module.scss";
// import { Context } from "../../../context/Context";
// import { localizeField } from "../../../utils/locale";
// import { IconButton } from "../../../components/common/IconButton";

// import { useMediaQuery, MediaQuery } from "../../../utils/hooks/mediaQuery";

// const BookTicketsButton = ({ onClick }) => {
//   const data = useStaticQuery(graphql`
//     query BookTicketsButtonQuery {
//       sanitySingletonMenus {
//         bookTickets {
//           linkText {
//             _type
//             en
//             sc
//             tc
//           }
//           slug
//         }
//       }
//     }
//   `);

//   const isTablet = useMediaQuery(MediaQuery.Tablet);
//   const bookTickets = data.sanitySingletonMenus.bookTickets;
//   const { language } = useContext(Context);
//   const path = language.path
//     ? `${language.path}${bookTickets.slug}`
//     : bookTickets.slug;

//   return (
//     <menu className={styles.menu} onClick={onClick}>
//       <div className={styles.bookTicketsButton}>
//         <FLink to={`/${bookTickets.slug}`}>
//           {localizeField(bookTickets.linkText, language.path)}
//         </FLink>
//         <IconButton to={`/${path}`} bg="black" border={false}>
//           {localizeField(bookTickets.linkText, language.path)}
//         </IconButton>
//       </div>
//     </menu>
//   );
// };

// export { BookTicketsButton };
