export const languages = {
  ENGLISH: {
    name: "English",
    label: "En",
    path: "",
    locale: "en",
  },
  TRADITIONAL_CHINESE: {
    name: "Tradiontal Chinese",
    label: "繁",
    path: "/tc",
    locale: "tc",
  },
  SIMPLIFIED_CHINESE: {
    name: "Simplified Chinese",
    label: "简",
    path: "/sc",
    locale: "sc",
  },
};

// this confusingly named function returns a function!
// in localize.js a function is created from the below function and sets the language code.
// effectively that function then becomes 'localize' below that accepts a value
// this will then either map through an array or return an object. It may return one or many values.
export const createLocaleTextGetter = (languageCode) => {
  const languages = [languageCode, "en"]; // last language in array is default;
  const localize = (value) => {
    if (!value) return;
    // is it an array or object
    if (Array.isArray(value)) {
      // reciprocal loop for each item in array
      return value.map((v) => localize(v, languages));
    } else if (typeof value == "object") {
      // regex test for string starting in pattern
      if (/^locale[A-Z]/.test(value?._type)) {
        const language = languages.find((lang) => value[lang]);
        return value[language];
      }
      return Object?.keys(value).reduce((result, key) => {
        result[key] = localize(value[key], languages);
        return result;
      }, {});
    }
    return value;
  };
  return localize;
};

export const localizeField = (fieldObj, languageCode) => {
  if (languageCode.length === 0) languageCode = "en";
  return fieldObj[languageCode];
};

export const languageFromPath = (path) => {
  const checkString = path?.substring(0, 4);

  switch (checkString) {
    case languages.SIMPLIFIED_CHINESE.path + "/":
      return languages.SIMPLIFIED_CHINESE;
    case languages.TRADITIONAL_CHINESE.path + "/":
      return languages.TRADITIONAL_CHINESE;
  }
  return languages.ENGLISH;
};
