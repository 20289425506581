import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';

// import { Link } from "gatsby";
import { FLink } from '../../common/FLink';
import { DJLogo } from '../../common/DJLogo';
import classNames from 'classnames';
import { Navbar } from '../Navbar';
import { BookTicketsButton } from '../BookTicketsButton';
import { Hamburger } from '../Hamburger';
import { springOne } from '../../../utils/common';
import { Context } from '../../../context/Context';
// import { colorModes } from '../../../utils/common';
import { useMediaQuery, MediaQuery } from '../../../utils/hooks/mediaQuery';

import * as styles from './Header.module.scss';

import { motion } from 'framer-motion';

const Header = ({ location }) => {
  const { menuOpen, setMenuOpen /*setColorMode*/ } = useContext(Context);

  const logoHeightDivider = 3;
  const [maxLogoHeight, setMaxLogoHeight] = useState(0);
  const [minLogoHeight, setMinLogoHeight] = useState(0);

  const pageUrls = ['home', 'works', 'show', 'contact'];

  const urlParts = location.pathname.split('/');
  const bookTicketsPage = urlParts.some((e) => e === 'book-tickets');

  // useEffect(() => {
  //   pageUrls.forEach((element, index) => {
  //     // if the pageUrl string is in the path set the colormode to its index
  //     if (urlParts.find((e) => e === element)) {
  //       setColorMode(colorModes[index]);
  //     }
  //   });
  //   // if none of the pageUrls are in the pathname set the colorMode to default
  //   if (!urlParts.some((r) => pageUrls.includes(r)))
  //     setColorMode(colorModes[0]);
  // }, [location.pathname]);

  useLayoutEffect(() => {
    setMaxLogoHeight(window.innerHeight / logoHeightDivider);

    const buttonHeight = parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        '--button-height'
      ),
      10
    );
    // const headerHeight = parseInt(
    //   getComputedStyle(document.documentElement).getPropertyValue(
    //     "--header-height"
    //   ),
    //   10
    // );
    // const pagePadding = parseInt(
    //   getComputedStyle(document.documentElement).getPropertyValue(
    //     "--page-padding"
    //   )
    // );
    setMinLogoHeight(buttonHeight);
    // 45 currently
  }, []);

  const isTablet = useMediaQuery(MediaQuery.Tablet);

  const menuVariants = isTablet
    ? {
        open: { opacity: 0.8, x: '80%' },
        closed: { opacity: 0, x: '100%' },
      }
    : {
        open: { opacity: 0.8, x: '50%' },
        closed: { opacity: 0, x: '100%' },
      };

  const handleBookClick = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.topBar}>
          <FLink
            to="/"
            onClick={() => setMenuOpen(false)}
            className={`${styles.menuTop} ${styles.logoLink}`}>
            <span className="visually-hidden">Daniel Jackson</span>
            {maxLogoHeight !== 0 && (
              <DJLogo
                className={styles.logo}
                menuOpen={menuOpen}
                minHeight={minLogoHeight}
                maxHeight={maxLogoHeight}
                logoHeightDivider={logoHeightDivider}
                small={
                  location.pathname !== '/' &&
                  location.pathname !== '/sc/' &&
                  location.pathname !== '/tc/'
                }
              />
            )}
          </FLink>

          <div className={`${styles.menuTop} ${styles.hamburger}`}>
            <Hamburger
              isOpen={menuOpen}
              onClick={() => setMenuOpen(!menuOpen)}
              strokeWidth="4"
              color="#ffffff"
              lineProps={{ strokeLinecap: 'round' }}
              transition={springOne}
            />
          </div>
        </div>
        <h2 id="mainmenulabel" className="visually-hidden">
          Main Menus
        </h2>
        <motion.nav
          animate={menuOpen ? 'open' : 'closed'}
          transition={springOne}
          initial={false}
          variants={menuVariants}
          aria-labelledby="mainmenulabel"
          className={styles.nav}>
          <div>
            <Navbar setMenuOpen={setMenuOpen} location={location} />
          </div>
        </motion.nav>
      </header>
    </>
  );
};

export { Header };
