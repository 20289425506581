// extracted by mini-css-extract-plugin
export var border = "IconButton-module--border--81a7c";
export var btn = "IconButton-module--btn--37f2c";
export var dark = "IconButton-module--dark--b8113";
export var green = "IconButton-module--green--d2414";
export var greeninverse = "IconButton-module--greeninverse--a215e";
export var greenoutline = "IconButton-module--greenoutline--85560";
export var grey = "IconButton-module--grey--37b17";
export var homeIcon = "IconButton-module--home-icon--a3d95";
export var icon = "IconButton-module--icon--46a24";
export var invertpeach = "IconButton-module--invertpeach--eb14a";
export var purple = "IconButton-module--purple--00698";
export var salmon = "IconButton-module--salmon--c8b0c";
export var transparent = "IconButton-module--transparent--81c78";
export var typeBody = "IconButton-module--type-body--80234";
export var typeLarge = "IconButton-module--type-large--40cd3";
export var typeMedium = "IconButton-module--type-medium--63296";